import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import { DataGrid, gridHeader } from "@certcapture/react-components";
import { STag, SPagination, SLoader } from "@avalara/skylab-react";
import {
    selectPage,
    fetchCustomersAPI,
    selectLoading,
    selectSortColumn,
    setSortColumn,
    selectVendorColumns,
    selectCustomerFiltersState,
    selectFormState,
    selectCustomFields,
    getVendorColumnList,
} from "../../../app/customerSlice";
import Upgrade from "../../upgradeTier/OnlineBuyDialog";
import VendorFilter from "./VendorFilter";
import "../customers/customer.css";
import toast from "../../../hooks/toast";
import { selectResponseType, setResponseType } from "../../../app/savedSearchesSlice";
import SaveSearchDialog from "../../sharedDialogs/SaveSearchDialog";
import { Messages } from "../../../shared/constants";
import { isEligibleUser } from "../../../shared/sessionUtility";
import featureFlag from "../../../featureToggler/featureFlag";
import LabelElement from "../../sharedComponents/LabelElementInGrid";
import RecordCount from "../RecordCount";

function VendorGrid() {
    const dispatch = useDispatch();
    const [showToast] = toast();
    const [submitting, setSubmitting] = useState(false);
    const loading = useSelector(selectLoading);
    const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
    const page = useSelector(selectPage, shallowEqual);
    const pageData = page.data;
    const paginateData = page.paginate;
    const sortColumn = useSelector(selectSortColumn);
    const selectedColumns = useSelector(selectVendorColumns);
    const saveSearchStatus = useSelector(selectResponseType);
    const vendorFilterState = useSelector(selectCustomerFiltersState);
    const formState = useSelector(selectFormState, shallowEqual);
    const customVendorFields = useSelector(selectCustomFields);

    const isEligibleUserManageVendorsColumn = dispatch(
        isEligibleUser(featureFlag.customers.manageCustomersColumns, true)
    );
    const isEligibleUserCustomFields = dispatch(
        isEligibleUser(featureFlag.customers.customFields, true)
    );

    const isUserEligibleVendorLabels = dispatch(
        isEligibleUser(featureFlag.customers.customerLabels)
    );

    const savedSearchToast = useCallback(() => {
        switch (saveSearchStatus) {
            case "success": {
                dispatch(setResponseType(""));
                return showToast("success", Messages.saved_Success);
            }

            case "failure": {
                dispatch(setResponseType(""));
                return showToast("error", Messages.saved_failure);
            }

            default:
                return null;
        }
    }, [dispatch, saveSearchStatus, showToast]);

    useEffect(() => {
        if (saveSearchStatus) savedSearchToast();
    }, [saveSearchStatus, savedSearchToast]);

    useEffect(() => {
        dispatch(getVendorColumnList(isEligibleUserCustomFields, isUserEligibleVendorLabels));
    }, [dispatch, isEligibleUserCustomFields]);

    async function sortFunction(e) {
        setSubmitting(true);
        const column = e.detail.id;
        const order = sortColumn[0] === column ? !sortColumn[1] : true;
        dispatch(setSortColumn([column, order]));
        await dispatch(fetchCustomersAPI(paginateData.rowsPerPage, 1, false));
        setSubmitting(false);
    }

    const getCustomerLabels = customerLabels => {
        if (customerLabels) {
            return [...customerLabels.filter(label => label)];
        }
        return [];
    };

    const headerColumns = [
        gridHeader(
            "Vendor code",
            "customerNumber",
            (value, row) => (
                <Link className="font-semibold customer-link" to={`/vendor/${row.id}`}>
                    {row.customerNumber}
                </Link>
            ),
            null,
            null,
            null,
            null,
            sortFunction,
            "customerNumber"
        ),
        gridHeader("Vendor name", "name", null, null, null, null, null, sortFunction, "name"),
        gridHeader(
            "Address",
            ["city", "state"],
            (value, row) => (
                <span>
                    {row.addressLine1} {row.city ? `${row.city}, ` : ""}{" "}
                    {row.state ? row.state.initials : ""} {row.zip}{" "}
                    {row.country ? row.country.initials : ""}
                </span>
            ),
            null,
            null,
            null,
            null,
            null,
            "address"
        ),
        gridHeader(
            "Active certificates",
            "activeCertificateCount",
            (value, row) => (
                <div>
                    <div className="text-left active-count">{row.activeCertificateCount}</div>
                    <STag
                        color="blue-light"
                        className="margin-left-xs tagText"
                        hidden={!row.expiringSoonCertificateCount}>
                        <span slot="content">{`${row.expiringSoonCertificateCount} expiring soon`}</span>
                    </STag>
                </div>
            ),
            null,
            "activeCert-td",
            null,
            null,
            null,
            "activeCertificateCount"
        ),
        gridHeader(
            "Alternate ID",
            "alternateId",
            null,
            null,
            "fixed-column",
            null,
            null,
            sortFunction,
            "alternateId"
        ),
        gridHeader(
            "Phone",
            "phoneNumber",
            null,
            null,
            "fixed-column",
            null,
            null,
            sortFunction,
            "phoneNumber"
        ),
        gridHeader("Fax", "faxNumber", null, null, null, null, null, sortFunction, "faxNumber"),
        gridHeader(
            "Email",
            "emailAddress",
            null,
            null,
            null,
            null,
            null,
            sortFunction,
            "emailAddress"
        ),
        gridHeader(
            "Contact name",
            "contactName",
            null,
            null,
            null,
            null,
            null,
            sortFunction,
            "contactName"
        ),
        gridHeader(
            "Created",
            "created",
            value => new Date(value).toLocaleDateString(),
            null,
            "",
            null,
            null,
            sortFunction,
            "created"
        ),
        gridHeader(
            "Last modified",
            "modified",
            value => new Date(value).toLocaleDateString(),
            null,
            "",
            null,
            null,
            sortFunction,
            "modified"
        ),
        gridHeader(
            "Labels",
            "[customersLabels]",
            (value, row) => <LabelElement labels={[...getCustomerLabels(row.customersLabels)]} />,
            null,
            null,
            null,
            null,
            null,
            "vendorLabels"
        ),

        gridHeader("Vendor ID", "id", null, null, null, null, null, sortFunction, "id"),

        gridHeader("", "", "", "", null, null, null, null, "text-right", "text-right"),
    ];

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            await dispatch(
                fetchCustomersAPI(e.detail.rowsPerPage, e.detail.currentPage, false, e.detail)
            );
            setSubmitting(false);
        }
    };

    const getRowKey = row => {
        return row.id;
    };
    let modifiedColumns = [];

    const getCustomVendorValue = (customField, fieldName) => {
        const noDupes = [...new Set(customField)];
        if (noDupes[0] !== null && noDupes[0] !== "null") {
            const vmatch = noDupes?.filter(x => x?.includes(fieldName));
            if (vmatch.length !== 0) {
                const customValue = vmatch[0].split(":");
                return customValue[1].trim();
            }
            return vmatch;
        }
        return noDupes;
    };

    const arrangeColumns = columnlist => {
        const visibleColumns = columnlist?.filter(a => a.hidden === false);
        const tempCol = [modifiedColumns[0], modifiedColumns[1], modifiedColumns[2]];
        for (let j = 0; j < visibleColumns.length; ) {
            const item = modifiedColumns.find(obj => {
                return obj.id === visibleColumns[j].id;
            });
            tempCol.push(item);
            j += 1;
        }
        tempCol.push(modifiedColumns[modifiedColumns.length - 1]);
        modifiedColumns = tempCol;
    };

    const manageVendorColumns = () => {
        if (isEligibleUserManageVendorsColumn && customVendorFields.length !== 0) {
            customVendorFields.forEach(field => {
                if (!headerColumns.find(obj => obj.id === field.fieldName)) {
                    headerColumns.splice(
                        -1,
                        0,
                        gridHeader(
                            field.fieldName,
                            field.fieldName,
                            (value, row) => (
                                <span name={field.fieldName}>
                                    {getCustomVendorValue(row.customFields, field.fieldName)}
                                </span>
                            ),
                            null,
                            null,
                            null,
                            null,
                            null,
                            field.fieldName?.replace(/ /g, "")
                        )
                    );
                }
            });
        }
        modifiedColumns = headerColumns;
        selectedColumns?.forEach(col => {
            if (col.hidden) {
                modifiedColumns = modifiedColumns.filter(obj => {
                    return obj.id !== col.id;
                });
            }
        });
        arrangeColumns(selectedColumns);
        return modifiedColumns;
    };
    let table = null;
    if (submitting || loading) {
        table = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        );
    } else if (paginateData.totalRecords !== 0) {
        table = (
            <s-table-container>
                <DataGrid
                    columns={manageVendorColumns(selectedColumns)}
                    rows={pageData}
                    getKey={getRowKey}
                    loading={submitting}
                    inContainer
                    sortColumn={sortColumn}
                />
            </s-table-container>
        );
    }

    return (
        <React.Fragment>
            <VendorFilter />
            {paginateData.totalRecords > 0 ? (
                <div className="flex dl-flex-fill-height align-items-end margin-top-sm">
                    <RecordCount
                        selectedCount={0}
                        recordCount={new Intl.NumberFormat().format(paginateData.totalRecords)}
                        recordName="vendors"
                    />
                </div>
            ) : null}
            {table}
            {paginateData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={paginateData.rowsPerPage}
                    totalRecords={paginateData.totalRecords}
                    startIndex={paginateData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}

            <Upgrade
                isUpgradeDialogOpen={isUpgradeDialogOpen}
                setIsUpgradeDialogOpen={setIsUpgradeDialogOpen}
                type="request"
            />
            <SaveSearchDialog
                searchType="Vendor"
                criteria={{ ...vendorFilterState, ...formState }}
            />
        </React.Fragment>
    );
}
export default VendorGrid;
