import { createSlice } from "@reduxjs/toolkit";
import { pick, omit } from "lodash";
import axios from "../axios";
import { buildURL } from "../shared/Utils";
import toast from "../hooks/toast";

export const vendorPackagesSlice = createSlice({
    name: "vendorPackages",
    initialState: {
        isloading: false,
        page: {
            data: [],
            paginate: {
                pageNumber: 0,
                startIndex: -1,
                endIndex: 0,
                rowsPerPage: 0,
                totalRecords: 0,
            },
        },
        formState: {
            packageName: "",
            packageNameWhileLoading: "",
        },
        packageInfo: [],
        packageContent: [],
        sortColumn: ["modified", true],
        noDataType: "",
        postError: "",
    },

    reducers: {
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setNoDataType: (state, action) => {
            state.noDataType = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setPackageCertificateCount: (state, action) => {
            state.packageCertificateCount = action.payload;
        },
        setFormState: (state, action) => {
            state.formState = action.payload;
        },

        setPackageContent: (state, action) => {
            state.packageContent = action.payload;
        },

        setPackageInfo: (state, action) => {
            state.packageInfo = action.payload;
        },
    },
});

const [showToast] = toast();
export const {
    setLoading,
    setPage,
    setPageToNull,
    setSortColumn,
    setNoDataType,
    setPackageContent,
    setPackageInfo,
    setFormState,
} = vendorPackagesSlice.actions;

export const selectPage = state => {
    return state.vendorPackages.page;
};

export const selectSortColumn = state => {
    return state.vendorPackages.sortColumn;
};

export const selectIsLoading = state => {
    return state.vendorPackages.isloading;
};

export const selectNoData = state => {
    return state.vendorPackages.noDataType;
};

export const selectFormState = state => {
    return state.vendorPackages.formState;
};

export const selectPackageContent = state => {
    return state.vendorPackages.packageContent;
};

export const selectPackageInfo = state => {
    return state.vendorPackages.packageInfo;
};

export const fetchVendorPackagesAsync =
    (top = 10, skip = 0, onLanding, paginateDetails) =>
    async (dispatch, getState) => {
        const { isLoading, sortColumn, formState } = getState().vendorPackages;

        if (isLoading) {
            return;
        }

        dispatch(setLoading(true));
        const sort = `${sortColumn[0]} ${sortColumn[1] ? "DESC" : "ASC"}`;

        let filter = "";
        if (formState?.packageName) {
            filter = `packageName like '${formState?.packageName.trim()}'`;
        }

        const apiURI = buildURL(
            `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/vendor-packages`,
            filter,
            "",
            top,
            skip,
            sort
        );

        const response = await axios.get(`${apiURI}`, { withCredentials: true });

        if (response?.data[0]?.code) {
            onLanding ? dispatch(setNoDataType("onLanding")) : dispatch(setNoDataType("onSearch"));
            dispatch(setPageToNull());
        } else if (response?.data) {
            dispatch(setNoDataType(""));
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.currentPage,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response?.data?.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 10,
                      totalRecords: response?.data?.count,
                  };
            dispatch(
                setPage({
                    data: response?.data?.value,
                    paginate: paginateData,
                })
            );
        } else {
            dispatch(setPageToNull([]));
        }

        dispatch(setLoading(false));
    };

export const postVendorPackageApi = packageData => async (dispatch, getState) => {
    const { isLoading } = getState().vendorPackages;
    if (isLoading) {
        return;
    }
    dispatch(setLoading(true));
    await axios
        .post(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/vendor-packages`,
            packageData,
            { withCredentials: true }
        )
        .then(() => {
            showToast(
                "success",
                "Vendor package successfully created.",
                "vendor-package-post-success",
                true
            );
            dispatch(fetchVendorPackagesAsync());
        });
    dispatch(setLoading(false));
};

export const fetchVendorPackageContent = packageId => async (dispatch, getState) => {
    const { isLoading } = getState().vendorPackages;
    if (isLoading) {
        return;
    }

    const systemFieldList = [
        "client_id",
        "document_type_id",
        "exposure_zone_id",
        "exposure_zone_name",
        "tax_code_id",
        "form_name",
        "has_override",
        "tax_code_name",
        "template_tag",
        "template_type",
    ];
    dispatch(setLoading(true));
    await axios
        .get(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/vendor-packages-content?$filter=packageId eq ${packageId} `,
            { withCredentials: true }
        )
        .then(response => {
            if (!response.data[0]?.code) {
                const getData = response.data.value;
                const parsedData = getData.map(data => {
                    const tempFields = data.formData;
                    const fieldData = {};
                    const fieldList = omit(tempFields, systemFieldList);
                    const infoList = pick(tempFields, systemFieldList);

                    fieldData.fields = Object.keys(fieldList).map(key => {
                        return {
                            fieldName: key,
                            fieldValue: fieldList[key],
                        };
                    });

                    fieldData.taxCodeName = infoList.tax_code_name;
                    fieldData.regionName = infoList.exposure_zone_name;
                    fieldData.formName = infoList.form_name;
                    fieldData.contentId = data.id;
                    return fieldData;
                });
                dispatch(setPackageContent(parsedData));
            } else {
                dispatch(setPackageContent([]));
            }
        });
    dispatch(setLoading(false));
};

export const fetchVendorPackageById = packageId => async (dispatch, getState) => {
    const { isLoading } = getState().vendorPackages;
    if (isLoading) {
        return;
    }

    dispatch(setLoading(true));
    await axios
        .get(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/vendor-packages/${packageId}`,
            { withCredentials: true }
        )
        .then(response => {
            if (!response.data[0]?.code) {
                const getData = response.data;
                dispatch(setPackageInfo(getData));
            } else {
                dispatch(setPackageInfo([]));
            }
        });
    dispatch(setLoading(false));
};

export const postVendorPackageTracking = trackingData => async (dispatch, getState) => {
    const { isLoading } = getState().vendorPackages;
    if (isLoading) {
        return;
    }
    dispatch(setLoading(true));

    await axios.post(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/vendor-packages-tracking`,
        trackingData,
        { withCredentials: true }
    );
    dispatch(setLoading(false));
};

export default vendorPackagesSlice.reducer;
