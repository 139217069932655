import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useLocation, useRoutes } from "react-router-dom";
import { selectSession, selectSkylabHeader } from "../../app/sessionSlice";
import adobeAnalytics from "../../shared/adobeAnalytics";
import "./navigation.css";
import routes from "./RouteConfig";
import retailRouteConfig from "./RetailRouteConfig";
import { isRetailOnlyUser } from "../../shared/sessionUtility";
import { urlToPageMap } from "../../shared/Utils";

function Navigation(props) {
    const dispatch = useDispatch();
    const session = useSelector(selectSession, shallowEqual);
    const skylabHeader = useSelector(selectSkylabHeader, shallowEqual);
    const location = useLocation();
    const [hideHeader, setHideHeader] = useState(false);
    const route = useRoutes(routes(setHideHeader, props?.setHideNav));
    const retailRoutes = useRoutes(retailRouteConfig(setHideHeader, props?.setHideNav));
    const [topSub, setTopSub] = useState("Exemption Certificates");
    const ishealthCheckGoingOn = location.pathname.includes("health-check");
    const isRetailView = location.pathname.startsWith(`${urlToPageMap.retail}`);
    const isRetailOnly = dispatch(isRetailOnlyUser());

    const showSub = () => {
        if (
            session.subscriptions.includes("ECMPremium") ||
            session.subscriptions.includes("VEMPremium")
        ) {
            setTopSub("ECM Premium");
        } else if (
            session.subscriptions.includes("ECMPro") ||
            session.subscriptions.includes("VEMPro")
        ) {
            setTopSub("ECM Pro");
        } else if (session.subscriptions.includes("ECMEssentials")) {
            setTopSub("ECM Essentials");
        }
    };

    useEffect(() => {
        adobeAnalytics(location, session);
        if (session.subscriptions.length >= 1) {
            showSub();
        }
    }, [location, session]);

    return (
        <React.Fragment>
            {!ishealthCheckGoingOn && !isRetailView && !isRetailOnly && (
                <h4 className="margin-top-none margin-bottom-md" hidden={hideHeader}>
                    <span className="gray-darker workspace-letter-spacing margin-right-sm">
                        {topSub}
                    </span>
                    <span className="divider-right margin-right-sm" />
                    <span className="gray-dark margin-right-lg workspace-letter-spacing text-uppercase">
                        {session.activeCompany.name}
                    </span>
                    <a href={skylabHeader.switchCompanyUrl}>Switch company</a>
                </h4>
            )}
            {isRetailOnly && <div className="flex dl-flex-fill-height">{retailRoutes}</div>}
            {!isRetailOnly && <div className="flex dl-flex-fill-height">{route}</div>}
        </React.Fragment>
    );
}

export default React.memo(Navigation);
