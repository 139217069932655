import React, { useEffect, useState, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { gridHeader, DataGrid } from "@certcapture/react-components";
import { v4 as uuidv4 } from "uuid";
import { SSelect, SRow, SCol, SLoader } from "@avalara/skylab-react";
import toast from "../../hooks/toast";
import {
    selectIsPutPostLoading,
    setIsEditClicked,
    selectUserInfoRole,
    selectCompaniesSelectedCount,
    selectCompanyInfo,
    selectSubjectId,
    postUserRoleInfo,
    putUserRoleInfo,
    setCompanyInfo,
    getAccountCompanies,
    selectIsCompanyLoading,
    selectUserLocationsInfo,
} from "../../app/roleManagementSlice";
import { roleOptionList } from "../../shared/constants";
import { ecmRole } from "../navigation/Subscription";
import "./roleManagement.css";
import SelectRetailLocation from "./SelectRetailLocation";
import FeatureToggler from "../../featureToggler/FeatureToggler";
import featureFlag from "../../featureToggler/featureFlag";
import { isEligibleUser } from "../../shared/sessionUtility";

function RolePermissionCompanyWise() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showToast] = toast();
    const userRoles = useSelector(selectUserInfoRole, shallowEqual);
    const isLoading = useSelector(selectIsPutPostLoading);
    const companiesSelectedCount = useSelector(selectCompaniesSelectedCount);
    const companyInfo = useSelector(selectCompanyInfo, shallowEqual);
    const isCompanyLoading = useSelector(selectIsCompanyLoading);
    const userLocationsInfo = useSelector(selectUserLocationsInfo, shallowEqual);
    const subjectId = useSelector(selectSubjectId);
    const { userId } = useParams();
    const [currentRole, setCurrentRole] = useState(
        userRoles !== null ? userRoles[0].role : ecmRole.CEMAdmin
    );
    const [isAdvanceSelection, setIsAdvanceSelection] = useState(false);
    const [allCompanylocations, setAllCompanylocations] = useState([]);
    const [companyWiseLocations, setCompanyWiseLocations] = useState([]);
    const isUserCanAssignRetailLocation = dispatch(
        isEligibleUser(featureFlag.userRoleManagement.assignRetailLocation)
    );

    let locationIds = null;
    const checkIfAllCompanyRole =
        userRoles !== null && userRoles.length === 1 && userRoles[0].isAllCompany;
    const checkIfAllCompanyLocation =
        userLocationsInfo !== null &&
        userLocationsInfo.length > 0 &&
        userLocationsInfo[0].isAllCompany;
    const checkIfAllAdvanceCompanyRoleSame =
        userRoles !== null && userRoles.length === 1 && userRoles[0]?.companyIds?.length > 0;
    let checkIfAdvancedLocationsDiff = false;
    if (!checkIfAllCompanyLocation) {
        checkIfAdvancedLocationsDiff =
            userLocationsInfo.find((userLocationInfo, index) => {
                if (userLocationInfo?.locationIds && index === 0) {
                    locationIds = userLocationInfo.locationIds;
                }
                return (
                    index !== 0 && locationIds.join(",") !== userLocationInfo.locationIds.join(",")
                );
            })?.length > 0;
    }

    useEffect(() => {
        dispatch(getAccountCompanies(userId));
    }, [userId]);

    useEffect(() => {
        if (
            (checkIfAdvancedLocationsDiff && checkIfAllCompanyLocation) ||
            (checkIfAllCompanyRole === false && checkIfAllAdvanceCompanyRoleSame === false)
        ) {
            setIsAdvanceSelection(true);
        } else if (userLocationsInfo.length > 0 && userLocationsInfo[0]?.locationIds) {
            setAllCompanylocations(userLocationsInfo[0].locationIds);
        }
    }, [
        checkIfAllCompanyLocation,
        checkIfAdvancedLocationsDiff,
        checkIfAllCompanyRole,
        checkIfAllAdvanceCompanyRoleSame,
        userLocationsInfo.length,
        JSON.stringify(userLocationsInfo[0]?.locationIds),
    ]);

    useEffect(() => {
        if (isAdvanceSelection) {
            setCompanyWiseLocations(userLocationsInfo);
        }
    }, [isAdvanceSelection]);

    const gridRoleOptionList = role => {
        const optionList = roleOptionList.map(element => {
            const selectOption = {
                label: element.label,
                value: element.value,
                selected: false,
            };
            if (role === element.value) selectOption.selected = true;
            return selectOption;
        });
        return optionList;
    };

    const addRetailLocation = useCallback(item => {
        setAllCompanylocations(prevVal => {
            return [...prevVal, item.value];
        });
    }, []);

    const removeRetailLocation = useCallback(item => {
        setAllCompanylocations(prevVal => prevVal.filter(value => value !== item.value));
    }, []);

    const handleGridRoleChange = (value, companyId) => {
        dispatch(
            setCompanyInfo(
                companyInfo.map(element => {
                    const companyData = element;
                    return {
                        ...companyData,
                        role: element.id === companyId ? value : element.role,
                    };
                })
            )
        );
    };

    const onAdd = (e, companyDetail) => {
        setCompanyWiseLocations(prevVal => {
            let changed = false;
            const updateLocations = prevVal.map(location => {
                const updateLocation = { ...location };
                if (updateLocation.companyId === companyDetail.id) {
                    updateLocation.locationIds = [...updateLocation.locationIds, e.value];
                    changed = true;
                }
                return updateLocation;
            });
            if (!changed) {
                updateLocations.push({
                    companyId: companyDetail.id,
                    locationIds: [e.value],
                });
            }
            return updateLocations;
        });
    };

    const onRemove = (item, companyDetail) => {
        setCompanyWiseLocations(prevVal => {
            let changedIndex = null;
            const updateLocations = prevVal.map((location, index) => {
                const updateLocation = { ...location };
                if (updateLocation.companyId === companyDetail.id) {
                    updateLocation.locationIds = updateLocation.locationIds.filter(
                        id => id !== item.value
                    );
                    changedIndex = index;
                }
                return updateLocation;
            });
            if (changedIndex >= 0) {
                if (updateLocations[changedIndex].locationIds.length === 0)
                    updateLocations.splice(changedIndex, 1);
            }
            return updateLocations;
        });
    };

    function getValue(row) {
        return JSON.stringify(
            companyWiseLocations.filter(companyWiseLocation => {
                if (
                    companyWiseLocation.companyId?.toString() === row.id?.toString() ||
                    companyWiseLocation.isAllCompany
                ) {
                    return true;
                }
                return false;
            })[0]?.locationIds
        );
    }

    const headerColumns = [
        gridHeader(
            "Company name",
            "name",
            value => <span name="companyName">{value}</span>,
            null,
            "cloumn-width-control",
            null,
            null,
            null,
            "name"
        ),
        gridHeader(
            "Services",
            "service",
            () => <span name="service"> Exemption certificate management - Customers </span>,
            null,
            "cloumn-width-control",
            null,
            null,
            null,
            "service"
        ),
        gridHeader(
            "Role",
            "role",
            (value, row) => (
                <SSelect
                    inputId={row.id}
                    aria-label={row.id}
                    optionsList={gridRoleOptionList(value)}
                    onS-select={e => handleGridRoleChange(e.detail.item.value, row.id)}
                />
            ),
            null,
            "cloumn-width-control-role",
            null,
            null,
            null,
            "role"
        ),
    ];

    headerColumns.push(
        gridHeader(
            "Locations",
            "location",
            (value, row) => (
                <SelectRetailLocation
                    inputId={row.id}
                    value={getValue(row)}
                    onAdd={e => onAdd(e, row)}
                    onRemove={e => onRemove(e, row)}
                    multiple
                />
            ),
            null,
            "cloumn-width-control",
            null,
            null,
            null,
            "location"
        )
    );

    const getRowKey = () => {
        return uuidv4();
    };

    const saveRole = async () => {
        let rolePayload = [];
        let locationPayload = [];
        if (isAdvanceSelection) {
            const companiesAssignedAdminRole = [];
            const companiesAssignedManagerRole = [];
            const companiesAssignedCertificateReviewerRole = [];
            const companiesAssignedViewerRole = [];
            const companiesAssignedNoAccessRole = [];
            const companiesAssignedRetViewerRole = [];
            const companiesAssignedRetManagerRole = [];
            const companiesAssignedRetAssociateRole = [];

            companyInfo.forEach(x => {
                if (x.isSelected) {
                    switch (x.role) {
                        case ecmRole.CEMAdmin:
                            companiesAssignedAdminRole.push(x);
                            break;
                        case ecmRole.CEMManager:
                            companiesAssignedManagerRole.push(x);
                            break;
                        case ecmRole.CEMCertificateReviewer:
                            companiesAssignedCertificateReviewerRole.push(x);
                            break;
                        case ecmRole.CEMViewer:
                            companiesAssignedViewerRole.push(x);
                            break;
                        case ecmRole.RETManager:
                            companiesAssignedRetManagerRole.push(x);
                            break;
                        case ecmRole.RETAssociate:
                            companiesAssignedRetAssociateRole.push(x);
                            break;
                        case ecmRole.RETViewer:
                            companiesAssignedRetViewerRole.push(x);
                            break;
                        default:
                            companiesAssignedNoAccessRole.push(x);
                            break;
                    }
                } else {
                    companiesAssignedNoAccessRole.push(x);
                }
            });

            if (companiesAssignedAdminRole.length) {
                rolePayload.push({
                    role: ecmRole.CEMAdmin,
                    companyIds: companiesAssignedAdminRole.map(x => x.id),
                    isAllCompany: false,
                });
            }
            if (companiesAssignedManagerRole.length) {
                rolePayload.push({
                    role: ecmRole.CEMManager,
                    companyIds: companiesAssignedManagerRole.map(x => x.id),
                    isAllCompany: false,
                });
            }
            if (companiesAssignedCertificateReviewerRole.length) {
                rolePayload.push({
                    role: ecmRole.CEMCertificateReviewer,
                    companyIds: companiesAssignedCertificateReviewerRole.map(x => x.id),
                    isAllCompany: false,
                });
            }
            if (companiesAssignedViewerRole.length) {
                rolePayload.push({
                    role: ecmRole.CEMViewer,
                    companyIds: companiesAssignedViewerRole.map(x => x.id),
                    isAllCompany: false,
                });
            }
            if (companiesAssignedRetAssociateRole.length) {
                rolePayload.push({
                    role: ecmRole.RETAssociate,
                    companyIds: companiesAssignedRetAssociateRole.map(x => x.id),
                    isAllCompany: false,
                });
            }
            if (companiesAssignedRetViewerRole.length) {
                rolePayload.push({
                    role: ecmRole.RETViewer,
                    companyIds: companiesAssignedRetViewerRole.map(x => x.id),
                    isAllCompany: false,
                });
            }
            if (companiesAssignedRetManagerRole.length) {
                rolePayload.push({
                    role: ecmRole.RETManager,
                    companyIds: companiesAssignedRetManagerRole.map(x => x.id),
                    isAllCompany: false,
                });
            }
            if (companiesAssignedNoAccessRole.length) {
                rolePayload.push({
                    role: ecmRole.CEMNoAccess,
                    companyIds: companiesAssignedNoAccessRole.map(x => x.id),
                    isAllCompany: false,
                });
            }
            locationPayload = companyWiseLocations;
        } else {
            rolePayload = [
                {
                    role: currentRole,
                    companyIds: companyInfo.filter(x => x.isSelected).map(x => x.id),
                    isAllCompany: false,
                },
            ];
            if (allCompanylocations.length > 0)
                locationPayload = companyInfo
                    .filter(x => x.isSelected)
                    .map(x => x.id)
                    .map(c => {
                        return {
                            companyId: c,
                            locationIds: allCompanylocations,
                            isAllCompany: false,
                        };
                    });
        }

        if (userRoles === null)
            await dispatch(
                postUserRoleInfo(subjectId, userId, rolePayload, locationPayload, showToast)
            );
        else
            await dispatch(
                putUserRoleInfo(subjectId, userId, rolePayload, locationPayload, showToast)
            );
    };

    const handleCancel = () => {
        if (userRoles !== null) dispatch(setIsEditClicked(false));
        else navigate("/overview");
    };

    const handleCheckBox = () => {
        if (isAdvanceSelection) setIsAdvanceSelection(false);
        else setIsAdvanceSelection(true);
    };

    let table = null;
    if (isCompanyLoading)
        table = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader
                    id="page-loader"
                    className="medium"
                    aria-live="polite"
                    loading={isCompanyLoading}
                />
            </div>
        );
    else
        table = (
            <div className="pad-bottom-xl pad-top-sm">
                <s-table-container id="allow-overflow-on-grid">
                    <DataGrid
                        columns={headerColumns}
                        rows={companyInfo.filter(x => x.isSelected)}
                        getKey={getRowKey}
                        inContainer
                    />
                </s-table-container>
            </div>
        );

    return (
        <>
            <SRow className="pad-left-sm pad-top-sm">
                <label id="lbl-role" className="text-md" htmlFor="company-wise-role-select">
                    Role
                </label>
            </SRow>
            <SRow className="pad-left-sm">
                <span className="text-xs-strong pad-top-none">Default permission</span>
            </SRow>
            <SRow className="pad-left-sm">
                <span>
                    This user role applies to all the exemption certificate features in the{" "}
                    {companiesSelectedCount} companies the user has access to, unless you decide to
                    <br />
                    assign roles separately by company or service.
                </span>
            </SRow>
            <SRow>
                <SCol span="3">
                    <SSelect
                        inputId="company-wise-role-select"
                        aria-labelledby="lbl-role"
                        multiple={false}
                        showSelectionCount={false}
                        optionsList={roleOptionList.map(element => {
                            return {
                                label: element.label,
                                value: element.value,
                                selected: currentRole !== null && currentRole === element.value,
                            };
                        })}
                        onS-select={e => setCurrentRole(e.detail.item.value)}
                        disabled={isAdvanceSelection ? "disabled" : null}
                    />
                </SCol>
            </SRow>

            <FeatureToggler category="userRoleManagement" id="assignRetailLocation">
                <SRow className="pad-left-sm pad-top-sm">
                    <label id="lbl-location" className="text-md" htmlFor="default-location-select">
                        Retail Location
                    </label>
                </SRow>
                <SRow className="pad-left-sm">
                    <span>Assign locations separately by company or service.</span>
                </SRow>
                <SRow>
                    <SCol span="3">
                        <SelectRetailLocation
                            value={JSON.stringify(allCompanylocations)}
                            name="default-location-select"
                            onAdd={addRetailLocation}
                            onRemove={removeRetailLocation}
                            multiple
                            disabled={isAdvanceSelection || !isUserCanAssignRetailLocation}
                        />
                    </SCol>
                </SRow>
            </FeatureToggler>

            <SRow className="pad-left-sm pad-bottom-sm">
                <input
                    id="advance-checkbox"
                    name="advance-checkbox"
                    type="checkbox"
                    aria-label="Advanced roles and permissions Assign a different role by company or service"
                    checked={isAdvanceSelection}
                    onChange={() => handleCheckBox()}
                />
                <label htmlFor="advance-checkbox">
                    Advanced roles and permissions <br />
                    Assign a different role by company or service.
                </label>
            </SRow>
            {isAdvanceSelection ? (
                <SRow className="pad-left-none">
                    <SCol span="10">{table}</SCol>
                </SRow>
            ) : null}
            <hr />
            <SRow className="pad-left-sm pad-top-xs">
                <button
                    className={isLoading ? "primary loading" : "primary"}
                    onClick={saveRole}
                    disabled={companiesSelectedCount === 0}
                    data-ref="ecm.user-role-management.submit-form">
                    Save
                </button>
                <button className="tertiary" onClick={handleCancel}>
                    Cancel
                </button>
            </SRow>
        </>
    );
}

export default RolePermissionCompanyWise;
