import { useDispatch } from "react-redux";
import featureFlag from "./featureFlag";
import { validateSubscriptions } from "../shared/sessionUtility";

const FeatureToggler = props => {
    const { category, id, children, firstChild, secondChild } = props;
    const feature =
        featureFlag[category] && featureFlag[category][id] ? featureFlag[category][id] : null;
    let dom = secondChild || null;

    const dispatch = useDispatch();
    // check for subscription and tier
    const isValidated = dispatch(validateSubscriptions(feature));
    let isHiddenEnv = false;
    if (feature != null && Object.hasOwn(feature, "env") && feature.env) {
        isHiddenEnv = !feature.env.includes(process.env.REACT_APP_ENV);
    }
    if (!isHiddenEnv && isValidated) {
        dom = firstChild || children;
    }
    return dom;
};

export default FeatureToggler;
