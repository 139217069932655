import React, { useEffect, useState } from "react";
import { SCol, SLoader, SRow } from "@avalara/skylab-react";
import { useNavigate, useParams } from "react-router-dom";
import { uniq } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
    selectIsLoading,
    fetchVendorPackageContent,
    selectPackageContent,
} from "../../../app/vendorPackagesSlice";
import { fetchOutgoingLettersByAPI, selectCoverLetter } from "../../../app/coverLetterSlice";
import { selectDocumentTypeInfo, fetchDocumentTypeInfoByName } from "../../../app/settingsSlice";
import VendorPackageValidationError from "./VendorPackageValidationError";
import SelectPackageZone from "./SelectPackageZone";
import SelectPackageReasons from "./SelectPackageReason";
import PackageGenCert from "./PackageGenCert";
import "./vendorPackages.css";
import axios from "../../../axios";
import toast from "../../../hooks/toast";
import { SalesAndUseTax } from "../../../shared/constants";

function VendorPackageEdit() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector(selectIsLoading);
    const params = useParams();
    const [showToast] = toast();
    const [reasonDisabled, setReasonDisabled] = useState(true);
    const [initGenC, setInitGenC] = useState(false);
    const [checkLoading, setCheckLoadings] = useState(false);

    const [reasonError, setReasonError] = useState("valid");
    const [zoneError, setZoneError] = useState("valid");

    const [inputZone, setInputZone] = useState([]);
    const [inputReason, setInputReason] = useState([]);
    const [gencertZones, setGencertZones] = useState([]);
    const [gencertReasons, setGencertReasons] = useState([]);
    const [tempReasons, setTempReasons] = useState([]);
    const [staticContent, setStaticContent] = useState([]);
    const [userAction, setUserAction] = useState(false);
    const packageId = params.id;
    const coverLetter = useSelector(selectCoverLetter);
    const documentType = useSelector(selectDocumentTypeInfo);
    const [selectedReasons, setSelectedReasons] = useState([]);

    const existingContent = useSelector(selectPackageContent).map(element => {
        return `${element.taxCodeName} (${element.regionName})`;
    });

    useEffect(() => {
        if (params.id) {
            dispatch(fetchVendorPackageContent(params.id));
        }
        dispatch(fetchDocumentTypeInfoByName(SalesAndUseTax, true));
    }, [dispatch]); // eslint-disable-line

    useEffect(() => {
        dispatch(fetchOutgoingLettersByAPI("title eq 'Standard Vendor Template'"));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const clickRequest = () => {
        navigate(`/settings/default-exemption-forms/outgoing`);
    };

    useEffect(() => {
        if (inputZone.length < 1 && gencertZones.length < 1) {
            setReasonDisabled(true);
        }
    }, [inputZone, gencertZones]); // eslint-disable-line react-hooks/exhaustive-deps

    const checkValidation = () => {
        let valid = true;
        if (inputReason.length < 1) {
            setReasonError("error");
            valid = false;
        }
        if (inputZone.length < 1) {
            setZoneError("error");
            valid = false;
        }
        return valid;
    };

    const convertData = data => {
        if (data) {
            return data.join(",");
        }
        return "";
    };

    const saveStaticContent = async packId => {
        const promises = [];
        const message = "Certificate package data has been successfully saved";
        const type = "success";

        Object.values(staticContent).every(item => {
            const jsonString = {
                exposure_zone_name: item.regionName,
                tax_code_name: item.taxCodeName,
            };

            const contentData = {
                packageId: packId,
                exposureZoneId: item.regionID,
                taxCodeId: item.codeID,
                formTemplateTag: "STATIC",
                formData: jsonString,
            };

            promises.push(
                axios.post(
                    `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/vendor-packages-content`,
                    contentData,
                    {
                        withCredentials: true,
                    }
                )
            );
            return true;
        });

        Promise.all(promises).then(() => {
            if (gencertZones.length < 1) {
                showToast(type, message);
                navigate(`/search/vendor-packages/${packId}`);
            }
        });
    };

    const sortFilter = () => {
        const tmp = tempReasons;
        tmp.sort((a, b) => {
            const nameA = a.region.toUpperCase();
            const nameB = b.region.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });

        const sorted = tmp.map(idx => {
            const ops = {
                [idx.region]: idx.code,
            };
            return ops;
        });
        setGencertReasons(sorted);
    };

    const handleConfirm = async () => {
        sortFilter();
        if (checkValidation()) {
            setCheckLoadings(true);
            if (gencertZones.length >= 1) {
                saveStaticContent(packageId);
                setInitGenC(true);
            } else {
                saveStaticContent(packageId);
            }
            setCheckLoadings(false);
        }
    };

    const handleCancel = () => {
        navigate(`/search/vendor-packages/${params.id}`);
    };

    const zoneAdd = detail => {
        if (zoneError) {
            setZoneError("valid");
        }
        setInputZone([...inputZone, detail.value]);
    };

    const handleZoneRemove = mnl => {
        setUserAction(true);
        setInputZone(prevState => prevState.filter(prevItem => prevItem !== mnl.value));

        if (inputReason.length >= 1) {
            setInputReason([]);
        }
        if (gencertReasons.length >= 1) {
            setGencertReasons([]);
        }
        if (gencertZones.length >= 1) {
            setGencertZones([]);
        }

        if (staticContent.length >= 1) {
            setStaticContent([]);
        }

        if (inputZone.length < 1) {
            setReasonDisabled(true);
        } else {
            setReasonDisabled(false);
        }
    };

    const handleReasonRemove = mnl => {
        setUserAction(true);
        if (mnl.staticFile) {
            setStaticContent(prevState => {
                return prevState.filter(prevItem => prevItem.taxCodeName !== mnl.taxCodeName);
            });
        } else {
            setGencertZones(prevState => prevState.filter(prevItem => prevItem !== mnl.regionName));

            setTempReasons(prevState => {
                return prevState.filter(prevItem => prevItem.region !== mnl.regionName);
            });
        }
        setInputReason(prevState => prevState.filter(prevItem => prevItem !== mnl.taxCodeName));
        setSelectedReasons(prevState => prevState.filter(prevItem => prevItem !== mnl.value));
    };

    const disableToggle = () => {
        if (inputZone.length >= 1) {
            setReasonDisabled(false);
        } else {
            setReasonDisabled(true);
            setGencertReasons([]);
            setGencertZones([]);
            setInputZone([]);
            setStaticContent([]);
        }
    };
    const reasonAdd = detail => {
        if (reasonError) {
            setReasonError("valid");
        }
        if (detail.staticFile) {
            const newContent = {
                regionID: detail.regionId,
                codeID: detail.taxCodeId,
                regionName: detail.regionName,
                taxCodeName: detail.taxCodeName,
            };
            setStaticContent(prev => [...prev, newContent]);
            setInputReason([...inputReason, detail.taxCodeName]);
        } else {
            setGencertZones([...gencertZones, detail.regionName]);
            setTempReasons([
                ...tempReasons,
                { region: detail.regionName, code: detail.taxCodeName },
            ]);
        }

        setInputReason([...inputReason, detail.taxCodeName]);
        setSelectedReasons([...selectedReasons, detail.value]);
    };

    let vendorRequestDOM = "";
    if (isLoading || checkLoading) {
        vendorRequestDOM = (
            <div>
                <SRow>
                    <SCol className="inline">
                        <SLoader
                            loading={isLoading || checkLoading}
                            className="large"
                            aria-live="polite"
                        />
                    </SCol>
                </SRow>
            </div>
        );
    } else {
        vendorRequestDOM = (
            <div>
                <div hidden={initGenC}>
                    <h1> Add certificate to package</h1>
                    <span className="semi-bold">
                        Tell us where you&#39;re exempt and we&#39;ll generate the certificates for
                        each reason. We may ask for <br />
                        additional information if a certificate has unique fields. The available
                        region and reason choices are based on <br /> your
                        <button
                            className="packages-link margin-left-xs link"
                            onClick={() => clickRequest()}>
                            default form settings
                        </button>
                    </span>
                    <SRow className="margin-top-sm margin-bottom-sm">
                        <SCol span="4">
                            <SelectPackageZone
                                onAdd={zoneAdd}
                                onRemove={handleZoneRemove}
                                zoneError={zoneError}
                                onToggle={disableToggle}
                                forceDisable={setReasonDisabled}
                                value={inputZone}
                            />
                            {reasonError === "error" ? (
                                <VendorPackageValidationError fieldName="Exemption region" />
                            ) : null}
                        </SCol>
                    </SRow>
                    <SRow>
                        <SCol span="4" className="margin-top-sm">
                            <SelectPackageReasons
                                onAdd={reasonAdd}
                                onRemove={handleReasonRemove}
                                zoneIds={inputZone}
                                disabled={reasonDisabled}
                                reasonError={reasonError}
                                userAction={userAction}
                                exData={existingContent}
                                value={selectedReasons}
                            />

                            {reasonError === "error" ? (
                                <VendorPackageValidationError fieldName="Exemption reason" />
                            ) : null}
                        </SCol>
                    </SRow>

                    <SRow className="margin-top-sm margin-bottom-sm">
                        <SCol>
                            <hr className="margin-all-none" />
                            <button
                                id="vendor-save"
                                className="primary margin-top-xl large"
                                onClick={handleConfirm}>
                                Next
                            </button>
                            <button className="secondary large" onClick={handleCancel}>
                                Cancel
                            </button>
                        </SCol>
                    </SRow>
                </div>
                <div hidden={!initGenC}>
                    <PackageGenCert
                        packageId={packageId}
                        coverLetterId={coverLetter[0]?.id}
                        exposureZone={convertData(uniq(gencertZones))}
                        enableGencert={initGenC}
                        taxCode={JSON.stringify(gencertReasons)}
                        customerNumber="placeholder"
                        documentTypeId={documentType[0]?.id}
                        staticContent={staticContent}
                    />
                </div>
            </div>
        );
    }

    return vendorRequestDOM;
}
export default VendorPackageEdit;
