import { SCol, SRow } from "@avalara/skylab-react";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { urlToPageMap } from "../../shared/Utils";

function Retail(props) {
    useEffect(() => {
        props.setHideNav(true);
    }, [props.setHideNav]);

    const locations = useLocation();
    const params = useParams();

    const [colSpan, setColSpan] = useState("md-10 lg-7 xl-7 12");

    const fullScreenWhenThisURL = [
        `${urlToPageMap.retail}/${urlToPageMap.retailCustomerAndCertificates}`,
        `${urlToPageMap.retail}/${urlToPageMap.retailSearch}`,
    ];

    useEffect(() => {
        for (let i = 0; i < fullScreenWhenThisURL.length; i += 1) {
            let modifiedLocations = locations.pathname;
            // remove slash from end
            if (locations.pathname.endsWith("/")) {
                modifiedLocations = locations.pathname.slice(0, locations.pathname.length - 1);
            }

            // remove id from url and compare
            if (fullScreenWhenThisURL[i].endsWith("id") && Number.parseInt(params?.id, 10)) {
                modifiedLocations = modifiedLocations.replace(
                    `/${Number.parseInt(params?.id, 10)}`,
                    ""
                );
            }

            // compare and set layout
            if (
                modifiedLocations === fullScreenWhenThisURL[i].replace("/:id", "") ||
                modifiedLocations === fullScreenWhenThisURL[i]
            ) {
                setColSpan("md-10 12");
            }
        }

        return () => {
            // reset before exiting page
            setColSpan("md-10 lg-7 xl-7 12");
        };
    }, [locations.pathname]);

    return (
        <SRow>
            <SCol span={colSpan} center="md">
                <Outlet />
                <span />
            </SCol>
        </SRow>
    );
}

export default Retail;
