import React, { useState } from "react";
import { SDialog, SRow, SCol } from "@avalara/skylab-react";
import CertificateStatusTag from "../../sharedComponents/CertificateStatusTag";
import "./search.css";
import SearchCustomerCertificates from "./SearchCustomerCertificates";

function SearchCustomerCertificatesWithDialog(props) {
    const { certificates, customerName, customerNumber, customerAddress } = props;
    const [customerCertificateDialogOpen, setCustomerCertificateDialogOpen] = useState(false);

    const renderTableBody = () => {
        return Array(Math.ceil(certificates.length / 3))
            .fill()
            .map((_, i) => (
                <tr className="certificate-row">
                    {certificates.slice(i * 3, i * 3 + 3).map(item => (
                        <td>
                            <p className="flex">
                                {item.exposureZoneInitials}
                                {CertificateStatusTag(item)}
                                <br />
                            </p>
                        </td>
                    ))}
                </tr>
            ));
    };

    return (
        <>
            <SearchCustomerCertificates certificates={certificates.slice(0, 3)} />
            <button
                type="button"
                className="secondary max-width-buttton"
                onClick={() => setCustomerCertificateDialogOpen(dialogState => !dialogState)}>
                View all {certificates.length}
            </button>
            {customerCertificateDialogOpen ? (
                <SDialog
                    open={customerCertificateDialogOpen}
                    onS-dismiss={() => setCustomerCertificateDialogOpen(false)}
                    backgroundClose>
                    <div slot="header" id="preview">
                        Certificates for {customerName}
                    </div>
                    <div slot="body" className="preview-modal-body">
                        <SRow>
                            <SCol span={4} className="font-bold">
                                Customer code
                            </SCol>
                            <SCol span={6}>{customerNumber}</SCol>
                        </SRow>
                        <SRow>
                            <SCol span={4} className="font-bold">
                                Address
                            </SCol>
                            <SCol span={7}>
                                <span id="customer-address">{customerAddress}</span>
                            </SCol>
                        </SRow>
                        <SRow>
                            <s-table-container class="margin-all-md full-width">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Region</th>
                                        </tr>
                                    </thead>
                                    <tbody>{renderTableBody()}</tbody>
                                </table>
                            </s-table-container>
                        </SRow>
                    </div>
                    <div slot="footer" className="block">
                        <SRow>
                            <SCol>
                                <button
                                    className="primary"
                                    onClick={() => setCustomerCertificateDialogOpen(false)}>
                                    Close
                                </button>
                            </SCol>
                        </SRow>
                    </div>
                </SDialog>
            ) : null}
        </>
    );
}

export default SearchCustomerCertificatesWithDialog;
