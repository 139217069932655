import React, { forwardRef, useEffect, useState, useImperativeHandle } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SRow, SCol, SSelect, SLoader } from "@avalara/skylab-react";
import {
    getCustomFields,
    getCertificateCustomFields,
    selectCustomFields,
    selectCertificateCustomFields,
    selectIsLoadingCertificateCustomFields,
    selectCertificateExistingCustomFieldValues,
    setCertificateExistingCustomFieldValues,
    selectCertificateAddedCustomFieldValues,
    setCertificateAddedCustomFieldValues,
    getCustomFieldsValidationResults,
} from "../../app/certificateSlice";
import { errorTag, noErrorTag, getLabelForCustomField } from "../../shared/Utils";
import ValidationError from "./ValidationError";

const CertificateCustomField = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const certificateCustomFields = useSelector(
        props.certificateID ? selectCertificateCustomFields : selectCustomFields,
        shallowEqual
    );
    const isLoading = useSelector(selectIsLoadingCertificateCustomFields);
    const certificateExistingCustomFieldValues = useSelector(
        selectCertificateExistingCustomFieldValues,
        shallowEqual
    );
    const certificateAddedCustomFieldValues = useSelector(
        selectCertificateAddedCustomFieldValues,
        shallowEqual
    );
    const [validationResults, setValidationResults] = useState({});

    useEffect(() => {
        if (props.certificateID) {
            dispatch(getCertificateCustomFields(props.certificateID));
        } else {
            dispatch(getCustomFields());
        }
    }, [dispatch, props.certificateID]);

    // used for doing CSV injection validation which is currently not in use. Can be leveraged for any validation.
    useImperativeHandle(ref, () => ({
        validateCustomFields() {
            const customFieldsValidationResult = dispatch(getCustomFieldsValidationResults());
            setValidationResults(customFieldsValidationResult);
            return customFieldsValidationResult;
        },
    }));

    const updateCertificateCustomFields = async (
        e,
        type,
        add = true,
        isDeselectAll,
        valueExists
    ) => {
        let value = null;
        if (isDeselectAll) {
            value = null;
        } else if (type === "multi-select") {
            if (add) {
                if (valueExists) {
                    value = certificateExistingCustomFieldValues[e.target.id]
                        ? [
                              ...certificateExistingCustomFieldValues[e.target.id],
                              e.detail.item.value,
                          ]
                        : [e.detail.item.value];
                } else
                    value = certificateAddedCustomFieldValues[e.target.id]
                        ? [...certificateAddedCustomFieldValues[e.target.id], e.detail.item.value]
                        : [e.detail.item.value];
            } else if (valueExists) {
                const filteredValue = certificateExistingCustomFieldValues[e.target.id].filter(
                    item => {
                        return item !== e.detail.item.value;
                    }
                );
                value = filteredValue;
            } else {
                const filteredValue = certificateAddedCustomFieldValues[e.target.id].filter(
                    item => {
                        return item !== e.detail.item.value;
                    }
                );
                value = filteredValue;
            }
        } else if (type === "select" || type === "boolean") {
            value = e.detail.item.value;
        } else {
            value = e.target.value;
            if (e.target.className === "error") {
                setValidationResults(previousResults => ({
                    ...previousResults,
                    [e.target.id]: {
                        status: "passed",
                    },
                }));
            }
        }

        if (valueExists)
            dispatch(setCertificateExistingCustomFieldValues({ [e.target.id]: value }));
        else dispatch(setCertificateAddedCustomFieldValues({ [e.target.id]: value }));
    };

    const getOptions = (possibleValues, value, id, type) => {
        const splitPossibleValues = possibleValues.split("|");
        const options = [];

        if (id && certificateExistingCustomFieldValues[id] && value) {
            splitPossibleValues.forEach(element => {
                options.push({
                    label: getLabelForCustomField(type, element),
                    value: element,
                    selected:
                        props.certificateID &&
                        certificateExistingCustomFieldValues[id].includes(element),
                });
            });
            return options;
        }

        if (id && certificateAddedCustomFieldValues[id]) {
            splitPossibleValues.forEach(element => {
                options.push({
                    label: getLabelForCustomField(type, element),
                    value: element,
                    selected: certificateAddedCustomFieldValues[id].includes(element),
                });
            });
            return options;
        }

        splitPossibleValues.forEach(element => {
            options.push({
                label: getLabelForCustomField(type, element),
                value: element,
                selected: false,
            });
        });
        return options;
    };

    const hasValidValues = elementId => {
        return (
            !Object.keys(validationResults).includes(elementId.toString()) ||
            validationResults[elementId].status === "passed"
        );
    };

    const getErrorMessage = elementId => {
        return Object.keys(validationResults).includes(elementId) &&
            validationResults[elementId].status === "failed"
            ? validationResults[elementId].issues[0]
            : "";
    };

    return (
        <React.Fragment>
            {isLoading && (
                <SRow className="margin-top-sm">
                    <SCol>
                        <SLoader loading="true" aria-live="polite" />
                    </SCol>
                </SRow>
            )}

            {certificateCustomFields &&
                certificateCustomFields.map(certificateCustomField => (
                    <SRow key={certificateCustomField.id}>
                        <SCol span="12">
                            {certificateCustomField.type === "text" ? (
                                <React.Fragment>
                                    <label
                                        className="margin-top-sm"
                                        id={`lbl-certificate-custom-field-${
                                            certificateCustomField.exists
                                                ? certificateCustomField.fieldId
                                                : certificateCustomField.id
                                        }`}>
                                        {certificateCustomField.fieldName}
                                    </label>
                                    <input
                                        id={
                                            certificateCustomField.exists
                                                ? certificateCustomField.fieldId
                                                : certificateCustomField.id
                                        }
                                        type="text"
                                        aria-labelledby={`lbl-certificate-custom-field-${
                                            certificateCustomField.exists
                                                ? certificateCustomField.fieldId
                                                : certificateCustomField.id
                                        }`}
                                        onChange={e =>
                                            updateCertificateCustomFields(
                                                e,
                                                "input",
                                                false,
                                                false,
                                                certificateCustomField.exists
                                            )
                                        }
                                        defaultValue={certificateCustomField.value}
                                        disabled={props?.disabledCustom === true}
                                        className={
                                            hasValidValues(
                                                certificateCustomField.exists
                                                    ? certificateCustomField.fieldId
                                                    : certificateCustomField.id
                                            )
                                                ? noErrorTag
                                                : errorTag
                                        }
                                    />
                                    <ValidationError
                                        errorMsg={getErrorMessage(
                                            certificateCustomField.exists
                                                ? certificateCustomField.fieldId.toString()
                                                : certificateCustomField.id.toString()
                                        )}
                                    />
                                </React.Fragment>
                            ) : null}
                            {certificateCustomField.type === "select" ||
                            certificateCustomField.type === "boolean" ? (
                                <React.Fragment>
                                    <label
                                        className="margin-top-sm"
                                        htmlFor={
                                            certificateCustomField.exists
                                                ? certificateCustomField.fieldId
                                                : certificateCustomField.id
                                        }
                                        id={`lbl-certificate-custom-field-${
                                            certificateCustomField.exists
                                                ? certificateCustomField.fieldId
                                                : certificateCustomField.id
                                        }`}>
                                        {certificateCustomField.fieldName}
                                    </label>
                                    <SSelect
                                        id={
                                            certificateCustomField.exists
                                                ? certificateCustomField.fieldId
                                                : certificateCustomField.id
                                        }
                                        aria-labelledby={`lbl-certificate-custom-field-${
                                            certificateCustomField.exists
                                                ? certificateCustomField.fieldId
                                                : certificateCustomField.id
                                        }`}
                                        inputId={
                                            certificateCustomField.exists
                                                ? certificateCustomField.fieldId
                                                : certificateCustomField.id
                                        }
                                        selectionOptional
                                        optionsList={getOptions(
                                            certificateCustomField.possibleValues,
                                            certificateCustomField.value,
                                            certificateCustomField.exists
                                                ? certificateCustomField.fieldId
                                                : certificateCustomField.id,
                                            certificateCustomField.type
                                        )}
                                        onS-select={e => {
                                            updateCertificateCustomFields(
                                                e,
                                                certificateCustomField.type,
                                                true,
                                                false,
                                                certificateCustomField.exists
                                            );
                                        }}
                                        onS-deselect={e => {
                                            updateCertificateCustomFields(
                                                e,
                                                certificateCustomField.type,
                                                false,
                                                e.detail.isDeselectAll,
                                                certificateCustomField.exists
                                            );
                                        }}
                                        disabled={props?.disabledCustom ? "disabled" : null}
                                    />
                                </React.Fragment>
                            ) : null}

                            {certificateCustomField.type === "multi-select" ? (
                                <React.Fragment>
                                    <label
                                        className="margin-top-sm"
                                        htmlFor={
                                            certificateCustomField.exists
                                                ? certificateCustomField.fieldId
                                                : certificateCustomField.id
                                        }
                                        id={`lbl-certificate-custom-field-${
                                            certificateCustomField.exists
                                                ? certificateCustomField.fieldId
                                                : certificateCustomField.id
                                        }`}>
                                        {certificateCustomField.fieldName}
                                    </label>
                                    <SSelect
                                        id={
                                            certificateCustomField.exists
                                                ? certificateCustomField.fieldId
                                                : certificateCustomField.id
                                        }
                                        inputId={
                                            certificateCustomField.exists
                                                ? certificateCustomField.fieldId
                                                : certificateCustomField.id
                                        }
                                        aria-labelledby={`lbl-certificate-custom-field-${
                                            certificateCustomField.exists
                                                ? certificateCustomField.fieldId
                                                : certificateCustomField.id
                                        }`}
                                        multiple
                                        selectionOptional
                                        optionsList={getOptions(
                                            certificateCustomField.possibleValues,
                                            certificateCustomField.value,
                                            certificateCustomField.exists
                                                ? certificateCustomField.fieldId
                                                : certificateCustomField.id,
                                            certificateCustomField.type
                                        )}
                                        onS-select={e => {
                                            updateCertificateCustomFields(
                                                e,
                                                "multi-select",
                                                true,
                                                false,
                                                certificateCustomField.exists
                                            );
                                        }}
                                        onS-deselect={e => {
                                            updateCertificateCustomFields(
                                                e,
                                                "multi-select",
                                                false,
                                                e.detail.isDeselectAll,
                                                certificateCustomField.exists
                                            );
                                        }}
                                        disabled={props?.disabledCustom ? "disabled" : null}
                                    />
                                </React.Fragment>
                            ) : null}
                        </SCol>
                    </SRow>
                ))}
        </React.Fragment>
    );
});

export default React.memo(CertificateCustomField);
