import { SCol, SDialog, SIcon, SRow, SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import classnames from "classnames";
import parse from "html-react-parser";
import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { selectCoverLetterPreview } from "../../app/coverLetterSlice";
import { selectSession } from "../../app/sessionSlice";
import axios from "../../axios";
import axiosWithoutInterceptor from "../../axiosWithoutInterceptor";
import featureFlag from "../../featureToggler/featureFlag";
import toast from "../../hooks/toast";
import adobeAnalytics from "../../shared/adobeAnalytics";
import { DefaultCoverLetters } from "../../shared/constants";
import { errorTag, noErrorTag, validateEmail } from "../../shared/Utils";
import { isEligibleUser } from "../../shared/sessionUtility";
import CustomerTypeAhead from "../sharedComponents/CustomerTypeAhead";
import SelectCoverLetter from "../sharedComponents/SelectCoverLetter";
import SelectExemptionReasons from "../sharedComponents/SelectExemptionReasons";
import SelectExposureZone from "../sharedComponents/SelectExposureZone";
import "./request-dialog.css";
import SelectCustomerRegion from "./SelectCustomerRegion";

function RequestDialog(props) {
    const dialogName = "requestDialog";
    const dispatch = useDispatch();
    const [customer, setCustomer] = useState(props?.customer || null);
    const [email, setEmail] = useState(props?.customer?.emailAddress || "");
    const [emailError, setEmailError] = useState("");
    const [exposureZone, setExposureZone] = useState([props?.exposureZone]);
    const [exemptReason, setExemptReason] = useState(props?.taxCode);
    const [specifyError, setSpecifyError] = useState("");
    const [note, setNote] = useState("");
    const [getCurrentId, getNextId] = useId();
    const isEligibleUserRequestsFlag = dispatch(
        isEligibleUser(featureFlag.requestCertificates.singleRequest)
    );
    const [specify, setSpecify] = useState(
        !!(props.taxCode !== undefined && props.exposureZone !== undefined)
    );
    const [attach, setAttach] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const submitButtonClassNames = classnames({
        primary: true,
        small: true,
        loading: submitting,
    });
    const session = useSelector(selectSession, shallowEqual);
    const [showToast, hideToast] = toast();
    const [isShowMessageDetails, setIsShowMessageDetails] = useState(false);
    const coverLetter = useSelector(selectCoverLetterPreview);
    const regMsg = /\{\{CUSTOM_MESSAGE\}\}/;
    const regName = /\{\{FROM_BUSINESS_NAME\}\}/gi;
    const [customers, setCustomers] = useState([]);
    const [customerError, setCustomerError] = useState("");
    const [customerDataValidation, setCustomerDataValidation] = useState(false);

    const [deliveryMethod, setDeliveryMethod] = useState("Email");
    const [skipCoverLetter, setSkipCoverLetter] = useState("true");
    const setCustomerDataValidationValue = useCallback(value => {
        setCustomerDataValidation(value);
    }, []);

    useEffect(() => {
        if (props?.customer) {
            setCustomer(props.customer);
        }
        if (props?.customer?.emailAddress) {
            setEmail(props.customer.emailAddress);
        }
        if (props?.exposureZone) {
            setExposureZone([props.exposureZone]);
        }
        if (props?.taxCode && props?.exposureZone) {
            setSpecify(true);
        }
        adobeAnalytics({ pathname: "/requests/request-dialog" }, session);
        setEmailError("");
        setSpecifyError("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props?.customer?.emailAddress,
        props.exposureZone,
        props.taxCode,
        props.fromSingleRequests,
    ]);

    const closeRequestDialog = () => {
        // Closing both Request Dialog modals. One for specific certificate request & one for a general request.
        if (props.setCurrentRow) {
            props.setCurrentRow({});
        }
        setIsShowMessageDetails(false);
        setSpecify(false);
        setAttach(false);
        setExposureZone([]);
        setExemptReason();
        setNote("");
        props.setIsRequestDialogOpen(false);
        setSkipCoverLetter(true);
        if (props.setIsGeneralRequestDialogOpen) props.setIsGeneralRequestDialogOpen(false);
    };

    const addCustomer = useCallback(value => {
        setCustomer(value[0]);
        setEmail(value[0].emailAddress);
        setCustomerError("");
        setCustomers(prevCustomer => [...prevCustomer, ...value]);
    }, []);

    const removeCustomer = useCallback(
        e => {
            const customersClone = customers.slice();
            customersClone.splice(
                customersClone.findIndex(ele => ele.id === parseInt(e.value, 10)),
                1
            );
            setCustomers(customersClone);
        },
        [customers]
    );

    const handleChangeInput = setter => {
        return event => {
            if (event.target.value && event.target.className === "error") {
                if (event.target.name === "email") {
                    setEmailError("");
                }
            }

            setter(event.target.value);
        };
    };

    const handleCheckBoxChange = e => {
        if (e.target.id === "specify") {
            if (specify) {
                setSpecify(false);
                setAttach(false);
                setSkipCoverLetter(true);
                setExposureZone([]);
                setExemptReason();
            } else {
                setSpecify(true);
            }
        } else if (e.target.id === "attach-pdf") {
            if (attach) {
                setAttach(false);
                setSkipCoverLetter(true);
            } else {
                setAttach(true);
            }
        } else if (e.target.id === "cl-add") {
            if (skipCoverLetter) {
                setSkipCoverLetter(false);
            } else {
                setSkipCoverLetter(true);
            }
        }
    };

    const handleZoneChange = detail => {
        setExposureZone([...(exposureZone[0] !== undefined ? exposureZone : []), detail.label]);
    };

    const handleZoneRemove = (detail, isDeselectAll) => {
        if (isDeselectAll) {
            setExposureZone([]);
            return;
        }
        setExposureZone(exposureZone.filter(zone => zone !== detail?.label));
    };

    const handleReasonChange = detail => {
        setExemptReason(detail.label);
    };

    const showMessageDetails = () => {
        setIsShowMessageDetails(!isShowMessageDetails);
    };

    const formatCustomEmail = () => {
        let formattedContent;
        try {
            const baseContent = atob(coverLetter.htmlContent);
            formattedContent = baseContent.replaceAll(regName, session.activeCompany.name);
            formattedContent = formattedContent.replaceAll("â", "&apos;");
            formattedContent = props.customMessage
                ? formattedContent.replace(regMsg, props.customMessage)
                : formattedContent.replace(regMsg, "");
        } catch {
            showToast("error", "Could not display the cover letter, please try again later.");
        }
        return formattedContent;
    };

    const checkValidation = () => {
        let valid = true;
        setSpecifyError("");

        if (deliveryMethod === "Email") {
            if (!email) {
                setEmailError("Email address is required");
                valid = false;
            } else if (!validateEmail(email)) {
                setEmailError("Please enter a valid email address");
                valid = false;
            }
        }
        if (specify && (!exemptReason || exposureZone[0] === undefined)) {
            setSpecifyError("error");
            valid = false;
        }
        if (props.fromSingleRequests) {
            if (customers && customers.length === 0) {
                setCustomerError("error");
                valid = false;
            }
            if (customerDataValidation) {
                valid = false;
            }
        }
        return valid;
    };

    const postRequestAPI = () => {
        const defaultParams = {
            Recipient: email,
            CoverLetterTitle: coverLetter?.title || DefaultCoverLetters.ecmDefault,
            DeliveryMethod: deliveryMethod,
            Note: note,
            SkipCoverLetter: !!skipCoverLetter,
            IsCoverLetterOnly: true,
        };
        const params = { ...defaultParams };

        if (specify) {
            if (exposureZone.length !== 0) {
                params.ExposureZones = exposureZone;
            }
            if (exemptReason !== "") {
                params.ExemptReasons = [exemptReason];
            } else if (props.taxCode) {
                params.ExemptReasons = [props.taxCode];
            }
            const shipToStates = [];

            params.ExposureZones.forEach(zone => {
                shipToStates.push({ name: zone });
            });

            params.IsCoverLetterOnly = !attach;

            axios.put(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION}/customers/${customer.id}/shiptostates`,
                { ShipToStates: shipToStates },
                { withCredentials: true }
            );
        }

        // TODO: revisit single request dialog box logic
        if (params.DeliveryMethod === "Download") {
            params.SkipCoverLetter = false;
            params.IsCoverLetterOnly = specify && !attach;
        }

        axiosWithoutInterceptor
            .post(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/companies/${session.activeCompany.id}/requests/single-request/${customer.id}`,
                params,
                { withCredentials: true }
            )
            .then(response => {
                if (response.data) {
                    if (deliveryMethod === "Email") {
                        showToast(
                            "success",
                            `Document request sent. We emailed ${email}`,
                            "certRequest-Toast"
                        );
                    } else {
                        showToast(
                            "success",
                            "We're creating your request. It may take a few minutes.",
                            "download-success",
                            true,
                            "View the request file in My Downloads",
                            () => {
                                hideToast("download-success");
                                window.open(
                                    `https://${process.env.REACT_APP_DOCLOCKER_HOST}/mylockers/downloads`,
                                    "_blank",
                                    "noopener,noreferrer"
                                );
                            }
                        );
                    }
                }
            })
            .catch(error => {
                // If the matrix does not collect the V2 API will throw an error. Detect error code and auto send cover letter only
                if (error.response.data?.message?.includes("No Matrix entry")) {
                    axios
                        .post(
                            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/requests/single-request/${customer.id}`,
                            defaultParams,
                            { withCredentials: true }
                        )
                        .then(response => {
                            if (response.data) {
                                showToast(
                                    "success",
                                    `No exemption matrix entry was found for the exemption region and exemption reason selected. Sending a cover letter only request for ${email}`
                                );
                            }
                        });
                }
            });
    };

    const updateEmail = () => {
        if (customer?.emailAddress !== email) {
            axios.put(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION}/customers/${customer.id}`,
                { customerNumber: customer.customerNumber, emailAddress: email },
                {
                    withCredentials: true,
                }
            );
        }
    };

    const handleSubmitRequest = async event => {
        event.preventDefault();
        if (checkValidation()) {
            setSubmitting(true);
            await postRequestAPI();
            await updateEmail();
            setSubmitting(false);
            closeRequestDialog();
        }
    };

    return (
        <SDialog
            id={dialogName}
            open={props.isRequestDialogOpen}
            onS-dismiss={e => {
                closeRequestDialog(e);
            }}
            aria-modal="true">
            <div slot="header" id="requestCertificate">
                Request a certificate
            </div>
            <div slot="body" className="request-modal-body">
                <SRow>
                    <SCol span="auto">
                        {props?.customer?.customerName || props?.customer?.name ? (
                            <span className="block">
                                This certificate request will be emailed to :{" "}
                                {props.customer.name || props.customer.customerName}
                            </span>
                        ) : (
                            <CustomerTypeAhead
                                handleAdd={addCustomer}
                                handleRemove={removeCustomer}
                                value={customers}
                                error={customerError}
                                multiple={false}
                                setCustomerDataValidation={setCustomerDataValidationValue}
                                fromSingleRequests={props.fromSingleRequests}
                                label="Customers"
                            />
                        )}

                        <label htmlFor="delivery-method" id="lbl-delivery-method">
                            Delivery method
                        </label>
                        <SSelect
                            name="delivery-method"
                            inputId="delivery-method"
                            optionsList={[
                                {
                                    label: "Email",
                                    value: "Email",
                                    selected: deliveryMethod === "Email",
                                },
                                {
                                    label: "Mail (Download and print)",
                                    value: "Download",
                                    selected: deliveryMethod === "Download",
                                },
                            ]}
                            noSearch
                            onS-select={e => setDeliveryMethod(e.detail.item.value)}
                        />

                        {(props?.customer?.customerCount === 1 && customer?.emailAddress) ||
                        deliveryMethod === "Download" ? (
                            <span className="block margin-top-md text-xs" />
                        ) : (
                            <React.Fragment>
                                <label htmlFor={getNextId()}>Email</label>
                                <input
                                    name="email"
                                    id={getCurrentId()}
                                    type="text"
                                    className={emailError !== "" ? errorTag : noErrorTag}
                                    value={email || ""}
                                    required
                                    onChange={handleChangeInput(setEmail)}
                                />

                                {emailError ? (
                                    <div className="input-msg">
                                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                                        <span className="top-xs">{emailError}</span>
                                    </div>
                                ) : null}
                            </React.Fragment>
                        )}
                        <SelectCoverLetter label="Certificate request template" />
                        <input
                            id="specify"
                            type="checkbox"
                            aria-label="Specify a region and reason for the exemption certificate"
                            checked={specify}
                            onChange={e => handleCheckBoxChange(e)}
                        />
                        <label htmlFor="specify" className="margin-bottom-sm">
                            Specify a region and reason for the exemption certificate
                        </label>

                        {specify ? (
                            <SRow>
                                <SCol className="margin-left-lg" span="7">
                                    {customer?.id ? (
                                        <SRow className="margin-top-sm">
                                            <SCol>
                                                <SelectCustomerRegion
                                                    onAdd={handleZoneChange}
                                                    onRemove={handleZoneRemove}
                                                    customerRegionDisabled={false}
                                                    value={exposureZone[0]}
                                                    customerId={customer?.id}
                                                    multiple
                                                />
                                            </SCol>
                                        </SRow>
                                    ) : (
                                        <SRow className="margin-top-sm">
                                            <SCol>
                                                <SelectExposureZone
                                                    onAdd={handleZoneChange}
                                                    onRemove={handleZoneRemove}
                                                    value={exposureZone}
                                                    multiple
                                                    exposureZoneDisabled={false}
                                                />
                                            </SCol>
                                        </SRow>
                                    )}

                                    <SRow className="margin-top-sm">
                                        <SCol>
                                            <SelectExemptionReasons
                                                onAdd={handleReasonChange}
                                                onRemove={() => setExemptReason("")}
                                                value={exemptReason}
                                                multiple={false}
                                                disabled={false}
                                            />
                                        </SCol>
                                    </SRow>
                                    {specifyError ? (
                                        <div className="input-msg specify-error">
                                            <SIcon
                                                name="alert-circle-filled"
                                                className="error-icon"
                                                aria-hidden="true"
                                            />
                                            <span className="top-xs">
                                                A region and exemption reason are required
                                            </span>
                                        </div>
                                    ) : null}
                                </SCol>
                            </SRow>
                        ) : null}

                        <SRow>
                            <SCol span="9">
                                <input
                                    id="attach-pdf"
                                    type="checkbox"
                                    aria-label="Attach a copy of the certificate to this request"
                                    checked={attach}
                                    onChange={e => handleCheckBoxChange(e)}
                                    disabled={!specify}
                                />
                                <label className="margin-top-sm" htmlFor="attach-pdf">
                                    Attach a copy of the certificate to this request
                                </label>
                            </SCol>
                        </SRow>

                        <SRow>
                            <SCol span="8">
                                <input
                                    id="cl-add"
                                    name="cl-add"
                                    type="checkbox"
                                    aria-label="Add the cover letter to the attached certificate form"
                                    checked={skipCoverLetter === false}
                                    disabled={!attach}
                                    onChange={e => {
                                        handleCheckBoxChange(e);
                                    }}
                                />
                                <label htmlFor="cl-add" className="margin-bottom-sm">
                                    Add the cover letter to the attached certificate form
                                </label>
                            </SCol>
                        </SRow>
                        <div>
                            <button
                                className="ghost-blue icon-trailing pad-all-none"
                                onClick={showMessageDetails}>
                                View and personalize this message
                                <SIcon
                                    name={isShowMessageDetails ? "chevron-down" : "chevron-up"}
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                        {isShowMessageDetails ? (
                            <div>
                                <div>
                                    <label htmlFor={getNextId()}>
                                        Add to the message if you&apos;d like
                                    </label>
                                    <textarea
                                        id={getCurrentId()}
                                        name="note"
                                        onChange={handleChangeInput(setNote)}
                                        value={note}
                                    />
                                </div>
                                <label>Message</label>
                                {"id" in coverLetter ? parse(formatCustomEmail()) : ""}
                            </div>
                        ) : null}
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    disabled={!isEligibleUserRequestsFlag}
                    onClick={() => closeRequestDialog()}>
                    Cancel
                </button>
                <button
                    className={submitButtonClassNames}
                    onClick={handleSubmitRequest}
                    disabled={!isEligibleUserRequestsFlag}
                    name="submit-request">
                    Send the request
                </button>
            </div>
        </SDialog>
    );
}

export default RequestDialog;
