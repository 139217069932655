import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SCol, SDialog, SRow, STabs, SIcon } from "@avalara/skylab-react";
import { v4 as uuidv4 } from "uuid";
import { selectSelectedTabItem, setSelectedTabItem } from "../../../../app/retailLocationSlice";
import { selectStates } from "../../../../app/commonSlice";
import { formatDateWithHyphen } from "../../../../shared/Utils";
import "./retailLocation.css";
import FeatureToggler from "../../../../featureToggler/FeatureToggler";
import featureFlag from "../../../../featureToggler/featureFlag";
import { isEligibleUser } from "../../../../shared/sessionUtility";

const RetailLocationDetailDialog = React.memo(props => {
    const dispatch = useDispatch();
    const { location } = props;
    const selectedTabItem = useSelector(selectSelectedTabItem);
    const states = useSelector(selectStates, shallowEqual);
    const isUserEligibleEditSiteDetails = !dispatch(
        isEligibleUser(featureFlag.settings.editRetailLocations)
    );

    const tabItems = [
        {
            id: "tab-site-details",
            label: "Site details",
            tabPanelId: "tab-site-details",
            selected: selectedTabItem === 0,
        },
        {
            id: "tab-site-settings",
            label: "Site settings",
            tabPanelId: "tab-site-settings",
            selected: selectedTabItem === 1,
        },
    ];
    useEffect(() => {
        dispatch(setSelectedTabItem(0));
    }, [dispatch]);
    const onCancel = () => {
        props.setIsDetailDialogOpen(false);
        props?.setLocation({});
    };
    return (
        <SDialog
            id="detailLocation-dialog"
            open={props.isDetailDialogOpen}
            onS-dismiss={() => props.setIsDetailDialogOpen(false)}
            aria-modal="true"
            className="retail-dialog-width">
            <div slot="header" id="detailLocation">
                {location?.name}
            </div>
            <div slot="body">
                <SRow>
                    <SCol>
                        <h4 className="font-bold inline pad-left-sm pad-right-lg">Created</h4>
                        <span className="pad-left-xl">
                            {formatDateWithHyphen(location?.created)}
                        </span>
                    </SCol>
                </SRow>

                <SRow>
                    <SCol>
                        <h4 className="font-bold inline pad-left-sm">Last modified</h4>
                        <span className="pad-left-md">
                            {formatDateWithHyphen(location?.modified)}
                        </span>
                    </SCol>
                </SRow>
                <STabs
                    id="edit-location-tabs"
                    tabItems={tabItems}
                    onS-select={e => dispatch(setSelectedTabItem(e.detail.index))}
                />
                {selectedTabItem === 0 ? (
                    <>
                        <FeatureToggler category="settings" id="editRetailLocations">
                            <SRow>
                                <SCol className="margin-top-sm">
                                    <button
                                        className="font-bold link"
                                        onClick={() => {
                                            props.setIsDetailDialogOpen(false);
                                            props.setOpenAddUpdateDialog(true);
                                            props.setAddOrEdit("Edit");
                                        }}
                                        disabled={isUserEligibleEditSiteDetails}>
                                        <SIcon name="edit-pencil" aria-hidden="true" />
                                        <span className="margin-left-xs">Edit details</span>
                                    </button>
                                </SCol>
                            </SRow>
                        </FeatureToggler>

                        <SRow className="pad-top-md">
                            <SCol span="3">
                                <h4 className="font-bold inline">Site name</h4>
                            </SCol>
                            <SCol span="9">{location?.name}</SCol>
                        </SRow>
                        <SRow>
                            <SCol span="3">
                                <h4 className="font-bold inline">Site code</h4>
                            </SCol>
                            <SCol span="9">{location?.code}</SCol>
                        </SRow>
                        <SRow>
                            <SCol span="3">
                                <h4 className="font-bold inline">Address</h4>
                            </SCol>
                            <SCol span="9">
                                {`${location?.addressLine1} ${location?.addressLine2}, ${
                                    location?.city
                                }, ${states
                                    .filter(state => state.id === location?.stateId)
                                    .map(state => state.initials)} ${location?.zip}`.substring(
                                    0,
                                    50
                                )}
                            </SCol>
                        </SRow>
                    </>
                ) : null}
                {selectedTabItem === 1 ? (
                    <>
                        <FeatureToggler category="settings" id="editRetailLocations">
                            <SRow>
                                <SCol className="margin-top-sm">
                                    <button
                                        className="font-bold link"
                                        onClick={() => {
                                            props.setOpenEditSettingsDialog(true);
                                            props.setIsDetailDialogOpen(false);
                                        }}
                                        disabled={isUserEligibleEditSiteDetails}>
                                        <SIcon aria-hidden="true" name="edit-pencil" />
                                        <span className="margin-left-xs">Edit details</span>
                                    </button>
                                </SCol>
                            </SRow>
                        </FeatureToggler>
                        <SRow>
                            <SCol>
                                <ul className="margin-left-md margin-top-sm">
                                    {location?.useStateAsJurisdiction &&
                                    props?.acceptedRegionList?.length > 0 ? (
                                        <>
                                            <li>
                                                Limit certificates at this retail site to specific
                                                regions:
                                            </li>
                                            {props?.acceptedRegionList?.map(region => (
                                                <div
                                                    className="font-semibold pad-left-sm"
                                                    key={uuidv4()}>
                                                    {region}
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <li>
                                            This site accepts certificates from all U.S. regions
                                        </li>
                                    )}
                                    <li>Only show exemptions created at this retail site</li>
                                    <li>Include customer code in search filters</li>
                                </ul>
                            </SCol>
                        </SRow>
                    </>
                ) : null}
            </div>
            <div slot="footer">
                <button className="primary small" onClick={onCancel}>
                    Close
                </button>
            </div>
        </SDialog>
    );
});
export default RetailLocationDetailDialog;
