import React, { useState, useCallback, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SRow, SCol, SIcon, SFiltersPanel, SInputExtended, SMenu } from "@avalara/skylab-react";
import { Link, useNavigate } from "react-router-dom";
import {
    fetchCertificatesSearchAPI,
    selectCertificateFiltersState,
    clearCertificateFiltersState,
    clearFormState,
    setCertificateFiltersState,
    selectLoading,
    selectFormState,
    setFormState,
    selectPage,
    setSavedSearch,
    setSavedSearchState,
    selectNoData,
    setIsBulkDownloadCertificateDialogOpen,
    setRedirectURL,
    selectIsLoadingCertificateCount,
} from "../../../app/certificateSlice";
import FilterByCustomer from "../Filters/FilterByCustomer";
import FilterByCertificate from "../Filters/FilterByCertificate";
import CertificateColumnsManager from "./CertificateColumnsManager";
import { urlToPageMap, nextDays, previousDays } from "../../../shared/Utils";
import { isEligibleUser } from "../../../shared/sessionUtility";
import SelectSavedSearch from "../../sharedComponents/SelectSavedSearch";
import { setIsSavedSearchDialog } from "../../../app/savedSearchesSlice";
import { setIsExportSearchDialogOpen } from "../../../app/exportSearchSlice";
import FeatureToggler from "../../../featureToggler/FeatureToggler";
import BulkUpdateErrorDialog from "../../bulkUpdateCertificates/BulkUpdateErrorDialog";
import BulkUpdateChooseFieldDialog from "../../bulkUpdateCertificates/BulkUpdateChooseFieldDialog";
import featureFlag from "../../../featureToggler/featureFlag";
import "./certificate.css";

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

const CertificateFilter = React.memo(props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const certificateFiltersState = useSelector(selectCertificateFiltersState);
    const { isCustomerDetails, isCertificateDetails, savedSearch } = certificateFiltersState;
    const [openFilters, setOpenFilters] = useState(true);
    const noData = useSelector(selectNoData);
    const isLoading = useSelector(selectLoading);
    const formState = useSelector(selectFormState);
    const page = useSelector(selectPage);
    const paginateData = page.paginate;
    const { nameOrCode } = formState;
    const selectCustomerRegionsRef = useRef(null);
    const selectCertificateStatusRef = useRef(null);
    const selectCertificateRegionsRef = useRef(null);
    const selectexemptReasonsRef = useRef(null);
    const SelectSavedSearchRef = useRef(null);
    const scrollRef = useRef(null);
    const applyButton = document.querySelector('button[ref="apply-button"]');
    const resetButton = document.querySelector('button[ref="reset-button"]');
    const currentdate = new Date().toISOString().slice(0, 10);
    const isLoadingCertificateCount = useSelector(selectIsLoadingCertificateCount);
    const [isBulkUpdateChooseFieldDialogOpen, setIsBulkUpdateChooseFieldDialogOpen] =
        useState(false);
    const [isBulkUpdateErrorDialogOpen, setIsBulkUpdateErrorDialogOpen] = useState(false);

    const isEligibleUserValidatedSearchPageExport = dispatch(
        isEligibleUser(featureFlag.validatedSearchPage.export)
    );

    const isEligibleUserValidatedSearchPageManageEcmReports = dispatch(
        isEligibleUser(featureFlag.validatedSearchPage.manageEcmReports)
    );
    const isEligibleUserValidatedSearchPageExportCertificateImages = dispatch(
        isEligibleUser(featureFlag.validatedSearchPage.exportCertificateImages)
    );

    const isEligibleUserValidatedSearchPageBulkDelete = dispatch(
        isEligibleUser(featureFlag.validatedSearchPage.bulkDelete)
    );

    const isEligibleUserValidatedSearchPageBulkEdit = dispatch(
        isEligibleUser(featureFlag.validatedSearchPage.bulkEdit)
    );

    const isEligibleUserRequestCertificatesCampaign = dispatch(
        isEligibleUser(featureFlag.requestCertificates.campaign)
    );
    const isEligibleUserReviewCertificatesAdd = dispatch(
        isEligibleUser(featureFlag.reviewCertificate.add)
    );

    const executeScroll = () => scrollToRef(scrollRef);

    const dismissFilters = () => {
        setOpenFilters(false);
    };

    const showFilters = () => {
        setOpenFilters(!openFilters);
    };

    const applyFilters = useCallback(() => {
        if (!certificateFiltersState.certificateIdError)
            dispatch(fetchCertificatesSearchAPI(paginateData.rowsPerPage, 1, false));
        executeScroll();
    }, [dispatch, paginateData.rowsPerPage, certificateFiltersState.certificateIdError]);

    const resetFilters = useCallback(() => {
        dispatch(clearCertificateFiltersState());
        dispatch(clearFormState());
        if (SelectSavedSearchRef?.current) SelectSavedSearchRef?.current?.deselectAll();
        applyFilters();
    }, [applyFilters, dispatch]);

    const updateFilters = useCallback(
        async (id, value, isfilter = true) => {
            if (certificateFiltersState[id] !== value) {
                dispatch(
                    setCertificateFiltersState({
                        [id]: value,
                    })
                );
                if (isfilter) {
                    await applyFilters();
                }
            }
        },
        [applyFilters, dispatch, certificateFiltersState]
    );

    const handleSearchBoxSubmit = useCallback(() => {
        dispatch(fetchCertificatesSearchAPI(paginateData.rowsPerPage, 1, false));
    }, [dispatch, paginateData.rowsPerPage]);

    const clearSearchBox = async () => {
        await dispatch(
            setFormState({
                ...formState,
                nameOrCode: "",
            })
        );
        handleSearchBoxSubmit();
    };

    const handleInput = e => {
        dispatch(
            setFormState({
                ...formState,
                [e.detail.inputId]: e.detail.value.trim(),
            })
        );
    };

    useEffect(() => {
        let timeout = "";
        timeout = setTimeout(() => {
            handleSearchBoxSubmit();
        }, 400);
        return () => clearTimeout(timeout);
    }, [formState.nameOrCode]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (certificateFiltersState.nameOrCode !== "") {
            dispatch(
                setFormState({
                    nameOrCode: certificateFiltersState.nameOrCode,
                    nameOrCodeWhileLoading: "",
                })
            );
        }
    }, [certificateFiltersState.nameOrCode]);

    useEffect(() => {
        if (formState.nameOrCodeWhileLoading !== "" && !isLoading && !isLoadingCertificateCount) {
            handleSearchBoxSubmit();
        }
    }, [
        formState.nameOrCodeWhileLoading,
        handleSearchBoxSubmit,
        isLoading,
        isLoadingCertificateCount,
    ]);

    useEffect(() => {
        if (applyButton !== null) {
            if (isLoading || isLoadingCertificateCount) {
                applyButton.setAttribute("disabled", "disabled");
                resetButton.setAttribute("disabled", "disabled");
            } else {
                applyButton.removeAttribute("disabled");
                resetButton.removeAttribute("disabled");
            }
        }
    }, [applyButton, isLoading, isLoadingCertificateCount, resetButton]);

    const handleCheckBoxChange = useCallback(
        e => {
            if (certificateFiltersState[e.target.id] !== e.target.checked) {
                dispatch(
                    setCertificateFiltersState({
                        [e.target.id]: e.target.checked,
                    })
                );
                applyFilters();
            }
        },
        [applyFilters, dispatch, certificateFiltersState]
    );

    const handlebtnClick = (e, value) => {
        updateFilters(e.target.id, !value, false);
    };

    const handleChangeInput = useCallback(
        (id, value) => {
            return event => {
                dispatch(
                    setCertificateFiltersState({
                        [id]:
                            value ||
                            (event && event.target && event.target.value ? event.target.value : ""),
                    })
                );
            };
        },
        [dispatch]
    );

    const handleMultilineInputChange = useCallback(() => {
        return event => {
            if (event.target.id === "customerCodes") {
                if (
                    event.target.value.split("\n").filter(e => {
                        return e != null;
                    }).length <= 100
                ) {
                    handleChangeInput("customerCodeError", "")();
                } else {
                    // eslint-disable-next-line no-param-reassign
                    event.target.className = "error";
                    handleChangeInput(
                        "customerCodeError",
                        "Only 100 customer codes that can be searched."
                    )();
                }
                handleChangeInput("customerCodes", event.target.value)();
            }
            if (event.target.id === "certificateIds") {
                const validInputs = /(^[0-9\n]+$|^$)/;
                if (
                    event.target.value.split("\n").filter(e => {
                        return e != null;
                    }).length <= 100
                ) {
                    if (event.target.value.match(validInputs)) {
                        event.target.className = ""; // eslint-disable-line
                        handleChangeInput("certificateIdError", "")();
                    } else {
                        event.target.className = "error"; // eslint-disable-line
                        handleChangeInput(
                            "certificateIdError",
                            "Certificate Id should be numeric."
                        )();
                    }
                } else {
                    // eslint-disable-next-line no-param-reassign
                    event.target.className = "error";
                    handleChangeInput(
                        "certificateIdError",
                        "Only 100 certificicate Ids that can be searched."
                    )();
                }
                handleChangeInput("certificateIds", event.target.value)();
            }
        };
    }, [handleChangeInput]);

    const handleSavedSearchAdd = optObj => {
        const searchCriteriaObj = JSON.parse(
            optObj.value
                .replace("Last30days", previousDays(30))
                .replace("Next30days", nextDays(30))
                .replace("today", currentdate)
        );
        dispatch(clearCertificateFiltersState());
        dispatch(setSavedSearchState(searchCriteriaObj));
        dispatch(setSavedSearch(optObj.label));

        // setFormState will internally trigger a call to fetchCertificatesSearchAPI. So we shouldn't be making duplicate calls here.
        if (searchCriteriaObj?.nameOrCode) {
            dispatch(
                setFormState({
                    nameOrCode: searchCriteriaObj.nameOrCode,
                    nameOrCodeWhileLoading: "",
                })
            );
        } else {
            dispatch(fetchCertificatesSearchAPI(paginateData.rowsPerPage, 1, false));
        }
    };

    const handleSavedSearchRemove = () => {
        dispatch(clearCertificateFiltersState());
        dispatch(clearFormState());
        dispatch(setSavedSearch(""));
        dispatch(fetchCertificatesSearchAPI(paginateData.rowsPerPage, 1, false));
    };

    const OpenSaveSearchDialog = () => {
        dispatch(setIsSavedSearchDialog(true));
    };

    const OpenExportSearchDialog = () => {
        dispatch(setIsExportSearchDialogOpen(true));
    };

    const OpenBulkCertificateDownloadDialog = () => {
        dispatch(setIsBulkDownloadCertificateDialogOpen(true));
    };

    const navigateToRequests = () => {
        navigate("/requests/create", { state: { searchType: "certificate" } });
    };

    return (
        <React.Fragment>
            <FeatureToggler category="reports" id="viewReports">
                <SRow className="pad-top-sm">
                    <SCol span="12" className="text-right">
                        <Link className="font-semibold" to="/saved-searches" aria-label="Reports">
                            Reports
                        </Link>
                    </SCol>
                </SRow>
            </FeatureToggler>

            <SRow className="align-items-end">
                <SCol span="xl-8 6">
                    <SRow className="margin-left-none align-items-end">
                        <button
                            type="button"
                            onClick={showFilters}
                            className="secondary"
                            name="filter-btn">
                            <SIcon name="filter" className="margin-right-xs" aria-hidden="true" />
                            Filters
                        </button>
                        <SCol span="md-6 xl-6 5" className="pad-bottom-none">
                            <label htmlFor="nameOrCode">
                                Search by customer name, customer code, or certificate ID
                            </label>
                            <SInputExtended
                                inputId="nameOrCode"
                                aria-label="Customer name, customer code, or certificate ID"
                                placeholder="Customer name, customer code, or certificate ID"
                                type="search"
                                onS-input={e => handleInput(e)}
                                onS-search={e => handleSearchBoxSubmit(e)}
                                onS-clear={() => clearSearchBox()}
                                value={nameOrCode}
                                className={
                                    isLoading || isLoadingCertificateCount
                                        ? "disable-sinput-extended"
                                        : "none"
                                }
                            />
                        </SCol>
                        <CertificateColumnsManager />
                    </SRow>
                </SCol>
                <SCol span="4" className="flex justify-content-end">
                    <FeatureToggler category="validatedSearchPage" id="ellipsisMenu">
                        <span className="flex justify-content-start">
                            <SMenu>
                                <button
                                    slot="menu-trigger"
                                    className="secondary icon"
                                    aria-label="more"
                                    title="more">
                                    <SIcon name="more" aria-label="more" />
                                </button>
                                <ul slot="menu-items" id="menu1-id">
                                    <FeatureToggler category="reviewCertificate" id="add">
                                        <li>
                                            <button
                                                className="link"
                                                disabled={
                                                    isLoading ||
                                                    isLoadingCertificateCount ||
                                                    !isEligibleUserReviewCertificatesAdd
                                                }
                                                onClick={() => {
                                                    navigate(urlToPageMap.review);
                                                    dispatch(
                                                        setRedirectURL(
                                                            urlToPageMap["search-certificate"]
                                                        )
                                                    );
                                                }}>
                                                Add a certificate
                                            </button>
                                        </li>
                                    </FeatureToggler>
                                    <FeatureToggler category="validatedSearchPage" id="export">
                                        <li>
                                            <button
                                                disabled={
                                                    isLoading ||
                                                    isLoadingCertificateCount ||
                                                    !isEligibleUserValidatedSearchPageExport
                                                }
                                                className="link"
                                                onClick={OpenExportSearchDialog}>
                                                Export
                                            </button>
                                        </li>
                                    </FeatureToggler>
                                    <FeatureToggler
                                        category="validatedSearchPage"
                                        id="manageEcmReports">
                                        <li>
                                            <button
                                                disabled={
                                                    isLoading ||
                                                    isLoadingCertificateCount ||
                                                    !isEligibleUserValidatedSearchPageManageEcmReports
                                                }
                                                className="link"
                                                onClick={OpenSaveSearchDialog}>
                                                Save search as report
                                            </button>
                                        </li>
                                    </FeatureToggler>
                                    <FeatureToggler
                                        category="validatedSearchPage"
                                        id="exportCertificateImages">
                                        <li>
                                            <button
                                                disabled={
                                                    isLoading ||
                                                    isLoadingCertificateCount ||
                                                    !isEligibleUserValidatedSearchPageExportCertificateImages
                                                }
                                                className="link"
                                                onClick={OpenBulkCertificateDownloadDialog}>
                                                Export certificate images
                                            </button>
                                        </li>
                                    </FeatureToggler>
                                    <FeatureToggler category="validatedSearchPage" id="bulkDelete">
                                        {paginateData.totalRecords ? (
                                            <li>
                                                <button
                                                    disabled={
                                                        isLoading ||
                                                        isLoadingCertificateCount ||
                                                        !isEligibleUserValidatedSearchPageBulkDelete
                                                    }
                                                    className="link"
                                                    onClick={() =>
                                                        props.setIsBulkDeleteCertificateDialogOpen(
                                                            true
                                                        )
                                                    }>
                                                    Delete certificates
                                                </button>
                                            </li>
                                        ) : null}
                                    </FeatureToggler>
                                    <FeatureToggler category="validatedSearchPage" id="bulkEdit">
                                        {paginateData.totalRecords ? (
                                            <li>
                                                <button
                                                    disabled={
                                                        isLoading ||
                                                        isLoadingCertificateCount ||
                                                        !isEligibleUserValidatedSearchPageBulkEdit
                                                    }
                                                    className="link"
                                                    onClick={() =>
                                                        paginateData.totalRecords > 100
                                                            ? setIsBulkUpdateErrorDialogOpen(true)
                                                            : setIsBulkUpdateChooseFieldDialogOpen(
                                                                  true
                                                              )
                                                    }>
                                                    Update the certificates
                                                </button>
                                            </li>
                                        ) : null}
                                    </FeatureToggler>
                                </ul>
                            </SMenu>
                        </span>
                    </FeatureToggler>
                    <FeatureToggler category="requestCertificates" id="campaign">
                        <span className="flex justify-content-end margin-left-sm">
                            <button
                                id="requestCertificates"
                                disabled={
                                    isLoading ||
                                    isLoadingCertificateCount ||
                                    noData !== "" ||
                                    !isEligibleUserRequestCertificatesCampaign
                                }
                                className="primary"
                                title="Request certificates"
                                onClick={navigateToRequests}>
                                <SIcon name="send" className="margin-right-xs" aria-hidden="true" />
                                Request certificates
                            </button>
                        </span>
                    </FeatureToggler>
                </SCol>
            </SRow>
            <div className="hidden">
                <input
                    id="isCertificateActive"
                    name="isCertificateActive"
                    type="checkbox"
                    aria-label="Show only active certificates"
                    onChange={e => handleCheckBoxChange(e)}
                    checked={certificateFiltersState.isCertificateActive}
                    disabled={isLoading || isLoadingCertificateCount}
                />
                <label htmlFor="isCertificateActive">Show only active certificates</label>
            </div>
            <SFiltersPanel
                open={openFilters}
                id="certificate-filters-panel"
                onS-dismiss={dismissFilters}
                onS-reset={resetFilters}
                onS-apply={applyFilters}
                className="margin-top-xl margin-right-lg">
                <div slot="filters-panel-content" ref={scrollRef}>
                    <FeatureToggler category="reports" id="viewReports">
                        <SRow>
                            <SCol span="12" className="pad-bottom-sm">
                                <SelectSavedSearch
                                    ref={SelectSavedSearchRef}
                                    loading={isLoading || isLoadingCertificateCount}
                                    onAdd={e => handleSavedSearchAdd(e)}
                                    onRemove={e => handleSavedSearchRemove(e)}
                                    value={savedSearch}
                                    label="Reports"
                                    disabled={isLoading || isLoadingCertificateCount}
                                    searchType="Certificate"
                                />
                            </SCol>
                        </SRow>
                    </FeatureToggler>
                    <SRow>
                        <SCol span="12" className="pad-right-md pad-left-md">
                            <div>
                                <button
                                    id="isCertificateDetails"
                                    className="ghost-blue icon-trailing pad-all-none"
                                    onClick={e => handlebtnClick(e, isCertificateDetails)}>
                                    Certificate details
                                    <SIcon
                                        id="isCertificateDetails"
                                        name={isCertificateDetails ? "chevron-up" : "chevron-down"}
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                            <FilterByCertificate
                                isLoading={isLoading || isLoadingCertificateCount}
                                updateFilters={updateFilters}
                                handleCheckBoxChange={handleCheckBoxChange}
                                handleMultilineInputChange={handleMultilineInputChange}
                                applyFilters={applyFilters}
                                filterState={certificateFiltersState}
                                setFilterState={setCertificateFiltersState}
                                ref={{
                                    selectexemptReasonsRef,
                                    selectCertificateStatusRef,
                                    selectCertificateRegionsRef,
                                }}
                                usedBy="CertificateSearch"
                            />
                            <div>
                                <button
                                    id="isCustomerDetails"
                                    type="button"
                                    className="ghost-blue icon-trailing pad-all-none"
                                    onClick={e => handlebtnClick(e, isCustomerDetails)}>
                                    Customer details
                                    <SIcon
                                        id="isCustomerDetails"
                                        name={isCustomerDetails ? "chevron-up" : "chevron-down"}
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                            <FilterByCustomer
                                isLoading={isLoading || isLoadingCertificateCount}
                                updateFilters={updateFilters}
                                handleCheckBoxChange={handleCheckBoxChange}
                                handleMultilineInputChange={handleMultilineInputChange}
                                applyFilters={applyFilters}
                                filterState={certificateFiltersState}
                                setFilterState={setCertificateFiltersState}
                                ref={selectCustomerRegionsRef}
                                usedBy="CertificateSearch"
                            />
                        </SCol>
                    </SRow>
                </div>
            </SFiltersPanel>
            <BulkUpdateChooseFieldDialog
                isBulkUpdateChooseFieldDialogOpen={isBulkUpdateChooseFieldDialogOpen}
                setIsBulkUpdateChooseFieldDialogOpen={setIsBulkUpdateChooseFieldDialogOpen}
                certificateCount={paginateData.totalRecords}
            />
            <BulkUpdateErrorDialog
                isBulkUpdateErrorDialogOpen={isBulkUpdateErrorDialogOpen}
                setIsBulkUpdateErrorDialogOpen={setIsBulkUpdateErrorDialogOpen}
                bulkEditCertificateCount={paginateData.totalRecords}
            />
        </React.Fragment>
    );
});

export default CertificateFilter;
